<template>
  <div :class="['formular-locations', { 'is-disabled': disabled }]">
    <b-row v-show="!form.show">
      <b-col :cols="$root.mobile.up.key">
        <h2 class="h3">{{ $T('Search.Title') }}</h2>

        <div class="locations-search">
          <control-input
            id="SearchQueryControl"
            v-model="search.query"
            :placeholder="$T('Search.Control.Query.Placeholder')"
            :description="$T('Search.Control.Query.Description')"
            :disabled="disabled"
            @input="searchByQuery()"
            @keydown.native.esc="searchReset"
          />

          <div class="search-list">
            <div>
              <div class="list-noitemmessage" v-if="searchlocations.length <= 0" v-html="$T('Search.NoItemMessageHtml')"/>

              <div class="list-item" v-else v-for="location in searchlocations" :key="location.id">
                <b-form-row class="justify-content-between">
                  <b-col cols="auto">
                    <h3>{{ location.name }}</h3>
                  </b-col>

                  <b-col cols="auto">
                    <b-badge v-if="selectedLocationIds.includes(location.id)" variant="dark">{{ $T('List.Item.IsOnTheList') }}</b-badge>
                  </b-col>
                </b-form-row>

                <div class="item-properties">
                  <div class="properties-tabled">
                    <div class="property property-address">
                      <div class="property-label">{{ $T('List.Item.Label.Address') }}</div>
                      <div>{{ location.address }}</div>
                    </div>

                    <div class="property property-customerid">
                      <div class="property-label">{{ labelFormatter(locationsDefinition, 'customerId') }}</div>
                      <div v-html="valueFormatter(locationsDefinition, 'customerId', location.customerId).html"/>
                    </div>

                    <div class="property property-glnnumber">
                      <div class="property-label">{{ labelFormatter(locationsDefinition, 'glnNumber') }}</div>
                      <div v-html="valueFormatter(locationsDefinition, 'glnNumber', location.glnNumber).html"/>
                    </div>
                  </div>

                </div>

                <div class="item-actions">
                  <b-button variant="primary" size="sm" :disabled="disabled || selectedLocationIds.includes(location.id)" @click="searchAddLocationToSelected(location)"><b-icon icon="plus" class="mr-1"/>{{ $T('Search.Item.Select') }}</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>

      <b-col :cols="$root.mobile.up.key" :class="['mt-4', `mt-${$root.mobile.up.key}-0`]">
        <h2 class="h3">{{ $T('List.Title') }}</h2>

        <div class="selected-list">
          <div :class="['list-item', `item-type-${getLocationOrigin(location)}`, { 'is-requested': !location.isAccepted }]" v-for="(location, lIndex) in selectedLocations" :key="lIndex">
            <template v-if="getLocationOrigin(location) === 'newcomer'">
              <h3 class="h4">{{ location.location.name }}</h3>

              <div class="item-properties">
                <div class="properties-tabled">
                  <template v-for="(propertyValue, pKey) in location.location">
                    <div :class="['property', `property-${pKey.toLowerCase()}`]" v-if="[].concat(propertyValue || []).length > 0" :key="pKey">
                      <div class="property-label">{{ labelFormatter(locationsDefinition, pKey) }}</div>
                      <div v-html="valueFormatter(locationsDefinition, pKey, propertyValue).html"/>
                    </div>
                  </template>
                </div>

                <div class="property property-address">
                  <div class="property-label">{{ $T('List.Item.Label.Address') }}</div>
                  <div>{{ [location.addresses[0].street, `${location.addresses[0].zip} ${location.addresses[0].city}`, location.addresses[0].canton, location.addresses[0].country].filter(i => i).join(', ') }}</div>
                </div>
              </div>
            </template>

            <template v-else>
              <b-form-row class="justify-content-between">
                <b-col cols="auto">
                  <h3 class="h4">{{ location.name }}</h3>
                </b-col>

                <b-col cols="auto">
                  <b-badge v-if="!location.isAccepted" variant="dark">{{ $T('List.Item.isRequested') }}</b-badge>
                </b-col>
              </b-form-row>

              <div class="item-properties">
                <div class="properties-tabled">
                  <div class="property property-customerid">
                    <div class="property-label">{{ labelFormatter(locationsDefinition, 'customerId') }}</div>
                    <div v-html="valueFormatter(locationsDefinition, 'customerId', location.customerId).html"/>
                  </div>

                  <div class="property property-glnnumber">
                    <div class="property-label">{{ labelFormatter(locationsDefinition, 'glnNumber') }}</div>
                    <div v-html="valueFormatter(locationsDefinition, 'glnNumber', location.glnNumber).html"/>
                  </div>
                </div>

                <div class="property property-address">
                  <div class="property-label">{{ $T('List.Item.Label.Address') }}</div>
                  <div>{{ location.address }}</div>
                </div>
              </div>
            </template>

            <div class="item-actions">
              <b-button-group size="sm">
                <b-button variant="primary" size="sm" v-if="getLocationOrigin(location) === 'newcomer'" :disabled="disabled" @click="formEditLocation(location)"><b-icon icon="edit" class="mr-1"/>{{ $T('List.Item.Edit') }}</b-button>
                <b-button variant="danger" size="sm" :disabled="disabled" @click="selectedUpdateLocation(location, false)"><b-icon icon="trash" class="mr-1"/>{{ $T('List.Item.Remove') }}</b-button>
              </b-button-group>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end mt-3">
          <b-button variant="dark" size="sm" :disabled="disabled" @click="formToggle(true)">{{ $T('FormAdd.ToggleButton') }}</b-button>
        </div>
      </b-col>
    </b-row>

    <template v-if="form.show">
      <b-row>
        <b-col :cols="$root.mobile.up.key">
          <h2 class="h3">{{ $T('FormAdd.Title') }}</h2>

          <formular-entity
            id="UserLocation"
            :entityDefinition="locationsDefinition"
            :entityValues="form.editData ? form.editData.location : undefined"
            :triggerInit="form.editData[ID_PROPERTIES.newcomer] !== null"
            @formular:change="formData => formUpdateData('location', formData)"
          />
        </b-col>

        <b-col :cols="$root.mobile.up.key">
          <h2 class="h3">{{ $T('FormAdd.AddressTitle') }}</h2>

          <formular-entity
            id="UserLocationAddress"
            :entityDefinition="locationAddressesDefinition"
            :entityValues="form.editData ? form.editData.addresses[0] : undefined"
            :triggerInit="form.editData[ID_PROPERTIES.newcomer] !== null"
            @formular:change="formData => formUpdateData('addresses', formData)"
          />
        </b-col>
      </b-row>

      <b-row class="mt-3" align-v="center" align-h="between">
        <b-col cols="auto">
          <b-button variant="light" size="sm" :disabled="disabled" @click="formToggle(false)">{{ $T('FormAdd.AbortButton') }}</b-button>
        </b-col>

        <b-col cols="auto">
          <b-button variant="primary" size="sm" :disabled="disabled" @click="formAddLocationToSelected">{{ $T('FormAdd.SaveButton') }}</b-button>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { LOCATIONSSEARCH_PROPERTY_OPTIONS } from '@/assets/js/config/server'

import { copy } from '@/assets/js/helper/object'
import { labelFormatter, valueFormatter } from '@/assets/js/helper/entity'

import FormularEntity from '@/components/registration/FormularEntity'

const ID_PROPERTIES = LOCATIONSSEARCH_PROPERTY_OPTIONS.idProperties
const ID_PROPERTY_KEYS = Object.keys(ID_PROPERTIES).reduce((properties, oKey) => Object.assign(properties, { [ID_PROPERTIES[oKey]]: oKey }), {})
const NEWCOMER_FORMDATA_PROPERTIES = Object.assign({ [ID_PROPERTIES.newcomer]: null }, LOCATIONSSEARCH_PROPERTY_OPTIONS.newcomerProperties)
const NEWCOMER_FORMVALIDITY_PROPERTIES = Object.keys(LOCATIONSSEARCH_PROPERTY_OPTIONS.newcomerProperties).reduce((props, dKey) => Object.assign(props, { [dKey]: false }), {})

const SEARCH_QUERY_MINLENGTH = 3

export default {
  name: 'RegistrationFormularLocations',
  components: {
    FormularEntity
  },
  props: {
    initialValues: {
      type: Array,
      default: () => ([])
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ID_PROPERTIES,
      search: {
        query: null,
        locations: []
      },
      selected: {
        locations: [].concat(this.initialValues)
      },
      form: {
        show: false,
        data: copy(NEWCOMER_FORMDATA_PROPERTIES),
        editData: copy(NEWCOMER_FORMDATA_PROPERTIES),
        validity: copy(NEWCOMER_FORMVALIDITY_PROPERTIES)
      }
    }
  },
  computed: {
    locationsDefinition () {
      return this.$store.getters['locations/definition']
    },
    locationAddressesDefinition () {
      return this.$store.getters['locationaddresses/definition']
    },
    allEverLoadedLocations () {
      return this.$store.getters['locations/getAllEverLoadedDecreasedEntities']
    },
    searchlocations () {
      return this.search.locations
        .map(l => Object.assign({ isSelected: this.selected.locations.some(l => l.id === l[ID_PROPERTIES.existing]) }, l))
    },
    selectedLocations () {
      return this.selected.locations
        .map(l => this.getLocationOrigin(l) === 'existing' ? Object.assign({ [ID_PROPERTIES.existing]: l[ID_PROPERTIES.existing], isAccepted: l.isAccepted }, this.allEverLoadedLocations.find(e => e.id === l[ID_PROPERTIES.existing]) || {}) : l)
    },
    selectedLocationIds () {
      return this.selectedLocations.map(l => l.locationId)
    },
    formIsValid () {
      return Object.values(this.form.validity).every(v => v === true)
    }
  },
  methods: {
    labelFormatter,
    valueFormatter,
    getLocationOrigin (location = {}) {
      return ID_PROPERTY_KEYS[Object.keys(location).find(lKey => ID_PROPERTY_KEYS[lKey])]
    },
    searchByQuery (id = null) {
      const query = id !== null ? id : this.search.query.length >= SEARCH_QUERY_MINLENGTH ? this.search.query : null

      if (query) {
        this.$store.dispatch('locations/searchByQueryLimited', { data: { query } })
          .then(response => {
            if (id === null) this.search.locations = response
          })
      } else {
        this.search.locations = []
      }
    },
    searchAddLocationToSelected (location) {
      this.selectedUpdateLocation({
        [ID_PROPERTIES.existing]: location.id || location[ID_PROPERTIES.existing],
        isAccepted: location.isAccepted
      }, true)
    },
    searchReset () {
      this.search.query = null
      this.search.locations = []
    },
    selectedCreateInitialLocations () {
      this.selected.locations
        .forEach(location => {
          const origin = this.getLocationOrigin(location)

          if (origin === 'existing') {
            this.searchByQuery(location[ID_PROPERTIES.existing])
            this.searchAddLocationToSelected(location)
          } else if (origin === 'newcomer') {
            this.selectedUpdateLocation(location)
          }
        })
    },
    selectedUpdateLocation (location = {}, modify = null) {
      const locationOrigin = this.getLocationOrigin(location)

      if (locationOrigin) {
        const propertyName = ID_PROPERTIES[locationOrigin]
        const existingIndex = this.selected.locations.findIndex(l => l[propertyName] === location[propertyName])

        if (modify === false) {
          if (existingIndex >= 0) {
            this.$delete(this.selected.locations, existingIndex)
            this.onChange({
              formProperty: LOCATIONSSEARCH_PROPERTY_OPTIONS.formDataProperites[locationOrigin],
              controls: location,
              isDirty: true,
              isValid: this.selected.locations.some(l => l[propertyName] !== undefined),
              modify
            })
          }
        } else {
          this.$set(this.selected.locations, existingIndex >= 0 ? existingIndex : this.selected.locations.length, location)
          this.onChange({
            formProperty: LOCATIONSSEARCH_PROPERTY_OPTIONS.formDataProperites[locationOrigin],
            controls: location,
            isDirty: true,
            isValid: this.selected.locations.some(l => l[propertyName] !== undefined),
            modify
          })
        }
      }
    },
    formSetup (location = null) {
      if (this.form.show) {
        if (location !== null) {
          this.form.editData = location
          this.form.data[ID_PROPERTIES.newcomer] = this.form.editData[ID_PROPERTIES.newcomer]
        } else {
          this.form.data[ID_PROPERTIES.newcomer] = `${LOCATIONSSEARCH_PROPERTY_OPTIONS.formDataProperites.newcomer}-${new Date().getTime()}`
        }
      } else {
        this.form.data = copy(NEWCOMER_FORMDATA_PROPERTIES)
        this.form.editData = copy(NEWCOMER_FORMDATA_PROPERTIES)
        this.form.validity = copy(NEWCOMER_FORMVALIDITY_PROPERTIES)
      }
    },
    formToggle (state = null, location) {
      this.form.show = state !== null ? state : !this.form.show
      this.formSetup(location)
      this.$emit(`formular:addlocationform-${this.form.show ? 'open' : 'close'}`)
    },
    formEditLocation (location = {}) {
      if (this.getLocationOrigin(location) === 'newcomer') this.formToggle(true, location)
    },
    formUpdateData (propertyName = '', formData) {
      const DATA_DEFAULT = copy(NEWCOMER_FORMDATA_PROPERTIES[propertyName])

      this.form.validity[propertyName] = formData.isValid

      if (formData.isValid) {
        if (Array.isArray(DATA_DEFAULT)) {
          this.$set(this.form.data, propertyName, DATA_DEFAULT.concat(formData.controls))
        } else {
          this.$set(this.form.data, propertyName, formData.controls)
        }
      } else {
        this.$set(this.form.data, propertyName, DATA_DEFAULT)
      }
    },
    formAddLocationToSelected () {
      this.$root.$emit('UserLocation:validate')
      this.$root.$emit('UserLocationAddress:validate')

      if (this.formIsValid) {
        this.selectedUpdateLocation(this.form.data, true)
        this.formToggle(false)
      }
    },
    onChange (formData) {
      this.$emit('formular:change', formData)
    }
  },
  created () {
    this.selectedCreateInitialLocations()

    this.$store.dispatch('locations/getDefinition')
    this.$store.dispatch('locationaddresses/getDefinition')
  },
  watch: {
    '$props.disabled' (isDisabled) {
      if (isDisabled) {
        this.searchReset()
        this.formToggle(false)
      }
    }
  }
}
</script>

<style lang="scss">
$formularlocations-list-noitem-message-color: $danger !default;

$formularlocations-list-item-padding-y: $spacer !default;
$formularlocations-list-item-padding-x: $formularlocations-list-item-padding-y !default;
$formularlocations-list-item-border: $border-color $border-style $border-width !default;
$formularlocations-list-item-odd-bg: $gray-100 !default;
$formularlocations-list-item-even-bg: $body-bg !default;

$formularlocations-list-item-hover-bg: $gray-200 !default;

$formularlocations-list-item-property-label-gap: $spacer !default;
$formularlocations-list-item-property-label-font-size: $font-size-sm !default;
$formularlocations-list-item-property-label-color: $gray-600 !default;
$formularlocations-list-item-property-tabled-gap: $spacer * 0.5 !default;

$formularlocations-list-item-action-gap: $spacer * 0.5 !default;

$formularlocations-disabled-opacity: 0.25 !default;

.formular-locations {
  .locations-search {}

  .search-list,
  .selected-list {
    .list-noitem-message {
      color: $formularlocations-list-noitem-message-color;
    }

    .list-item {
      position: relative;
      padding: $formularlocations-list-item-padding-y $formularlocations-list-item-padding-x;
      background-color: $formularlocations-list-item-odd-bg;

      &:nth-child(2n) {
        background-color: $formularlocations-list-item-even-bg;
      }

      .item-properties {
        .property {
          .property-label {
            padding-right: $formularlocations-list-item-property-label-gap;
            font-size: $formularlocations-list-item-property-label-font-size;
            color: $formularlocations-list-item-property-label-color;
          }
        }

        .properties-tabled {
          display: table;

          .property {
            display: table-row;

            > div {
              display: table-cell;
            }
          }

          + .property {
            margin-top: $formularlocations-list-item-property-tabled-gap;
          }
        }
      }

      .item-actions {
        display: flex;
        justify-content: flex-end;
        margin-top: $formularlocations-list-item-action-gap;
      }

      &.item-type-existing {}

      &.item-type-newcomer {}

      + .list-item {
        border-top: $formularlocations-list-item-border;
      }

      &:hover {
        background-color: $formularlocations-list-item-hover-bg;
        transition: $transition-base;
      }
    }
  }

  .locations-form {}

  &.is-disabled {
    position: relative;
    opacity: $formularlocations-disabled-opacity;

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(white, 0.001);
    }
  }
}
</style>
