<template>
  <formular
    :id="id"
    :isBusy="isBusy"
    :controlDefinition="definition"
    :controlValues="entityValues"
    :triggerInit="triggerInit"
    :showButtons="false"
    @formular:init="$value => $emit('formular:init', $value)"
    @formular:change="$value => $emit('formular:change', $value)"
    @formular:touch="$value => $emit('formular:touch', $value)"
    @formular:reset="$value => $emit('formular:reset', $value)"
    @formular:invalidsubmit="$value => $emit('formular:change', $value)"
    @formular:submit="$value => $emit('formular:submit', $value)"
  />
</template>

<script>
import Formular from '@/components/form/Formular'

export default {
  name: 'RegistrationFormularEntity',
  components: {
    Formular
  },
  props: {
    id: {
      type: String,
      required: true
    },
    entityKey: {
      type: String,
      default: null
    },
    entityDefinition: {
      type: Object,
      default: () => ({})
    },
    entityValues: {
      type: Object,
      default: () => ({})
    },
    definitionModifier: {
      type: Function,
      default: null
    },
    triggerInit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      busyStates: {
        validators: true,
        entityDefinition: true
      }
    }
  },
  computed: {
    isBusy () {
      return Object.values(this.busyStates).some(s => s === true)
    },
    validatorsIs () {
      return this.$store.getters['validators/is']
    },
    validators () {
      return this.$store.getters['validators/get']
    },
    dynamicEntityDefinition () {
      return this.entityKey ? this.$store.getters[`${this.entityKey}/definition`] : null
    },
    definition () {
      const definition = Object.assign({}, this.entityDefinition, this.dynamicEntityDefinition, {
        properties: this.entityDefinition.properties || this.dynamicEntityDefinition.properties || []
      })

      return typeof this.definitionModifier === 'function' ? this.definitionModifier(definition) : definition
    }
  },
  created () {
    this.$store.dispatch('validators/get').finally(() => { this.busyStates.validators = false })

    if (this.entityKey && !this.dynamicEntityDefinition.id) {
      this.$store.dispatch(`${this.entityKey}/getDefinition`).finally(() => { this.busyStates.entityDefinition = false })
    } else {
      this.busyStates.entityDefinition = false
    }
  },
  watch: {
    '$props.entityDefinition' (definition) {
      this.entityDefinition = definition
    },
    '$props.entityValues' (values) {
      this.entityValues = values
    }
  }
}
</script>

<style lang="scss"></style>
